import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import {
  Form,
  SelectInput,
  TextInput,
  useCreate,
  useGetManyReference,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, TextField, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/serviceName';
import { SETTLEMENT_COVERAGE_TYPES, SETTLEMENT_PARTY_AT_FAULT, SETTLEMENT_REASONS } from '@/constants/settlements';
import { OrderActionContext } from '@/providers/OrderAction';
import onlyUnique from '@/utils/onlyUnique';

import CurrencyInput from '../AddTips/CurrencyInput';

const AddSettlementForm = () => {
  const { record: { id: orderId } = {} } = useShowContext();
  const { setAction } = useContext(OrderActionContext);
  const { control } = useFormContext();

  const { data: jobsData } = useGetManyReference(
    resources.JOBS,
    {
      target: 'order_id',
      id: orderId,
      sort: {
        field: 'product_id',
        order: 'ASC',
      },
    },
    {
      enabled: Boolean(orderId),
    },
  );

  const jobServiceIds = jobsData?.map(({ product_id: productId }) => productId).filter(onlyUnique);

  return (
    <>
      <Box p={2}>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="unitprice"
            control={control}
            rules={{ max: 2000 }}
            render={({ field, fieldState }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="unitprice-label">Amount</InputLabel>
                <CurrencyInput field={field} fieldState={fieldState} />
                {fieldState?.error && fieldState?.error?.type === 'max' ? (
                  <Typography variant="body2" color="error.main" component={Box} pt={1}>
                    Maximum settlement of $2,000 allowed
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <TextInput source="zendeskId" label="Zendesk Ticket Id" fullwidth variant="outlined" />
        </Box>

        <Box>
          <TextInput source="context" label="Zendesk Context" multiline fullwidth variant="outlined" />
        </Box>

        <Box>
          <SelectInput
            source="coverageType"
            label="Coverage Type"
            fullWidth
            variant="outlined"
            choices={Object.values(SETTLEMENT_COVERAGE_TYPES)}
          />
        </Box>
        <Box>
          <SelectInput
            source="settlementReason"
            label="Settlement Reason"
            fullWidth
            variant="outlined"
            choices={Object.values(SETTLEMENT_REASONS)}
          />
        </Box>
        <Box>
          <SelectInput
            source="partyAtFault"
            label="Party at Fault"
            fullWidth
            variant="outlined"
            choices={[
              ...(jobServiceIds?.map((serviceId) => SERVICES?.[serviceId] ?? { id: serviceId, name: serviceId }) ?? []),
              ...Object.values(SETTLEMENT_PARTY_AT_FAULT),
            ]}
          />
        </Box>
        <Box>
          <Controller
            defaultValue=""
            name="description"
            control={control}
            render={({ field }) => <TextField variant="outlined" label="Description" fullWidth {...field} />}
          />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const AddSettlement = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error adding settlement - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track(`Order Management - Settlement created`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        settlement_coverage_type: data.coverage_type,
        settlement_party_at_fault: data.party_at_fault,
        settlement_reason: data.settlement_reason,
        confirmationId,
      });

      notify('Settlement created', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = ({ description, coverageType, partyAtFault, settlementReason, unitprice, zendeskId, context }) => {
    const actionMetadata = {
      zendesk_id: zendeskId,
      context,
    };

    create(
      resources.ORDERS,
      {
        meta: {
          subResource: 'settlements',
          resourceId: orderId,
          resourceVersion: 'v2',
        },
        data: {
          unitprice: parseInt(0 - Number(`${unitprice}`) * 100, 10), // subtract from zero for negative and multiply by 100 to get cents values
          description,
          coverage_type: coverageType,
          party_at_fault: partyAtFault,
          reason: settlementReason,
          metadata: actionMetadata,
        },
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Settlement
      </Typography>
      <Divider />
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form onSubmit={onSubmit}>
            <AddSettlementForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default AddSettlement;
