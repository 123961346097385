import React, { useContext, useEffect } from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import { bool, string } from 'prop-types';
import {
  ArrayField,
  FunctionField,
  RecordContextProvider,
  useDataProvider,
  useGetManyAggregate,
  useListContext,
  useNotify,
  useRecordContext,
  useShowContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import {
  AttachMoney,
  Error,
  EventAvailable,
  EventBusy,
  Help,
  Info,
  LocalShipping,
  Lock,
  NoEncryption,
  PeopleAlt,
  PlaylistAdd,
  Remove,
  Schedule,
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Divider, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/quotes';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import useUniqueMarkets from '@/hooks/useUniqueMarkets';
import { QuoteContext } from '@/providers/Quote';
import formatCurrency from '@/utils/currency/formatCurrency';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import { serviceGroupHasService } from '@/utils/serviceGroup';
import getServiceName from '@/utils/serviceName/getServiceName';
import toPascalCase from '@/utils/toPascalCase';

import RouteInfo from '@/shared/RouteInfo';
import AddRemoveTruckAction from './AddRemoveTruckAction';

dayjs.extend(utc);
dayjs.extend(tz);

const Locations = () => {
  const { data: locationsData } = useListContext();

  if (!locationsData) return null;

  return (
    <Box display="flex" alignItems="center">
      <Box flex={1}>
        {locationsData.map((location) => (
          <Box key={location.location_id}>
            <RecordContextProvider value={location}>
              <FunctionField
                render={(record) => (
                  <TextFieldWrapper
                    value={formatAddressParts({
                      ...record.location,
                      line_1: toPascalCase(record.location.line_1),
                      line_2: record.location.line_2 ? toPascalCase(record.location.line_2) : '',
                      city: toPascalCase(record.location.city),
                    })}
                  />
                )}
              />
            </RecordContextProvider>
          </Box>
        ))}
      </Box>
      {locationsData?.length > 1 ? (
        <>
          <Divider orientation="vertical" flexItem />
          <RouteInfo locations={locationsData?.map(({ location }) => location)} />
        </>
      ) : null}
    </Box>
  );
};

const RemoveServiceGroupAction = ({ disabled }) => {
  const {
    record: { id: quoteId },
    record: quoteData,
    refetch,
  } = useShowContext();
  const serviceGroup = useRecordContext();
  const navigate = useNavigate();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { id: serviceGroupId } = serviceGroup;

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteId}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Service Group removed', {
      quoteId,
    });
    refetch();
    navigateOverview();
    notify('Quote updated', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error removing Service Group', {
      quoteId,
      error,
    });
    notify(`Error occurred removing service group on quote - ${error}`, { type: 'error' });
  };

  const removeServiceGroup = () => {
    const params = {
      data: [
        {
          op: 'remove',
          path: `/service_groups/${serviceGroupId}`,
        },
      ],
      id: quoteId,
      meta: {
        operationPatch: true,
      },
    };
    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const laborJobs = quoteData?.service_groups?.map((group) =>
    group.services.filter((service) => ![SERVICES.TRANSIT.id, SERVICES.PACKINGSERVICE.id].includes(service.service_id)),
  );

  const isLaborServiceGroup = (sg) =>
    !serviceGroupHasService(sg, SERVICES.TRANSIT.id) && !serviceGroupHasService(sg, SERVICES.PACKINGSERVICE.id);

  const allowTransitOnlyQuote = quoteData?.partner_id === 'ZIPPYCORPRELO';

  let canRemoveServiceGroup = true;
  // Handle cases where we don't want to allow removal of service group
  if (quoteData?.service_groups?.length <= 1) {
    // Only one service group left
    canRemoveServiceGroup = false;
  } else if (serviceGroupHasService(serviceGroup, 'TRANSIT') && serviceGroupHasService(serviceGroup, 'LOADINGUNLOADING')) {
    // Full service group
    canRemoveServiceGroup = false;
  } else if (isLaborServiceGroup(serviceGroup) && laborJobs?.flat().length <= 1 && !allowTransitOnlyQuote) {
    // Can't remove last labor job
    canRemoveServiceGroup = false;
  }

  return (
    <Button
      startIcon={<Remove />}
      variant="outlined"
      key="remove-service-group"
      disabled={disabled || !canRemoveServiceGroup}
      onClick={removeServiceGroup}
      color="accent"
      sx={{ mr: 'auto' }}
    >
      Remove
    </Button>
  );
};

RemoveServiceGroupAction.propTypes = {
  disabled: bool.isRequired,
};

const ServiceGroupActions = () => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const { forceInventoryUpdate, locations } = useContext(QuoteContext);

  const [uniqueMarkets] = useUniqueMarkets({ locations });

  const { data: marketsData } = useGetManyAggregate(
    resources.MARKETS,
    { ids: uniqueMarkets },
    { enabled: Boolean(uniqueMarkets?.length) },
  );

  const hasLoadingUnloading = serviceGroupHasService(record, 'LOADINGUNLOADING');
  const hasTransit = serviceGroupHasService(record, 'TRANSIT');
  const laborOnlyMarket = marketsData?.every((market) => market.is_labor_only);
  const isServiceGroupLocked = record.services?.every((service) => service.locked);

  const actions = [
    <RemoveServiceGroupAction
      key="remove-service-group"
      disabled={(forceInventoryUpdate || isServiceGroupLocked) ?? null}
    />,
    <Button
      variant="outlined"
      key="modify-service-details"
      onClick={() => navigate(`edit/modify-service-details/${record.id}`)}
      disabled={(forceInventoryUpdate || isServiceGroupLocked) ?? null}
    >
      Modify Service Details
    </Button>,
    <Button
      variant="outlined"
      key="modify-datetime"
      onClick={() => navigate(`edit/modify-datetime/${record.id}`)}
      disabled={(forceInventoryUpdate || isServiceGroupLocked) ?? null}
    >
      Modify Date / Time
    </Button>,
    ...(hasLoadingUnloading && hasTransit && !isServiceGroupLocked
      ? [<AddRemoveTruckAction key="remove-truck" serviceGroupId={record.id} />]
      : []),
    ...(hasLoadingUnloading && !hasTransit && !laborOnlyMarket && !isServiceGroupLocked
      ? [<AddRemoveTruckAction key="add-truck" add serviceGroupId={record.id} />]
      : []),
  ];

  return (
    <Box display="flex" gap={1}>
      {actions}
    </Box>
  );
};

const ServiceDetails = () => {
  const { data: services } = useListContext();

  const serviceChipIcon = (service) => {
    if (service.service_id === 'TRANSIT') {
      return <LocalShipping />;
    }
    return <PeopleAlt />;
  };

  const serviceChipPriceText = (service) => {
    if (service.estimated_pricing_strategy === 'HOURLY') {
      return `${formatCurrency(service.quoted_unit_price, 0)} / hr`;
    }
    return `${formatCurrency(service.quoted_unit_price, 0)}`;
  };

  if (!services) return null;

  return services?.map((service) => (
    <React.Fragment key={service.id}>
      <Chip icon={serviceChipIcon(service)} label={service.quoted_workers} />
      <Chip icon={serviceChipIcon(service)} label={serviceChipPriceText(service)} />
    </React.Fragment>
  ));
};

const ServiceGroupTotalHourlyChip = () => {
  const serviceGroup = useRecordContext();

  const isServiceGroupHourly = serviceGroup?.services?.every((service) => service.estimated_pricing_strategy === 'HOURLY');

  if (!serviceGroup || !isServiceGroupHourly) return null;

  if (serviceGroup.services?.length <= 1) return null;

  const serviceGroupTotalHourly = serviceGroup.services?.reduce((total, service) => total + service.quoted_unit_price, 0);

  return <Chip icon={<PlaylistAdd />} label={`${formatCurrency(serviceGroupTotalHourly, 0)} / hr`} />;
};

const ServiceGroupInfo = () => (
  <>
    <ServiceGroupLocked />
    <ServiceGroupAvailable />
  </>
);

const ServiceGroupLocked = () => {
  const serviceGroup = useRecordContext();

  return serviceGroup?.locked ? (
    <Tooltip title="Service is locked due to being completed on the order">
      <Lock fontSize="large" color="warning" />
    </Tooltip>
  ) : null;
};

const ServiceGroupAvailable = () => {
  const { isLongDistance } = useContext(QuoteContext);
  const serviceGroup = useRecordContext();

  const dataProvider = useDataProvider();

  const {
    mutate: createMultiple,
    data: { data: flexibleServiceGroupQuoteData } = {},
    isLoading: isFlexQuotesLoading,
    isError: isFlexQuotesError,
  } = useMutation(([resource, params]) => dataProvider.createMultiple(resource, params));

  // only expect one flex quote from this call, always grab first index
  const available = flexibleServiceGroupQuoteData?.[0]?.is_available;

  const isLongDistanceTransit =
    isLongDistance && !!serviceGroup?.services?.find(({ service_id: serviceId }) => serviceId === SERVICES.TRANSIT.id);

  const fetchServiceGroupAvailability = () => {
    // Fetch flex quote for the exact date/time the service group is set as to show availability as currently configured
    // Reuses the createMultiple hook from the flex calendar, but we only ever expect one quote to return as we're asking for a single slot on a single day here
    createMultiple([
      resources.QUOTES,
      {
        data: {
          service_group_id: serviceGroup.id,
          start_date: dayjs(serviceGroup.start_datetime).format('YYYY-MM-DD'),
          end_date: dayjs(serviceGroup.start_datetime).format('YYYY-MM-DD'),
          local_hours: [dayjs(serviceGroup.start_datetime).tz(serviceGroup.start_timezone).hour()],
        },
        meta: {
          subResource: 'flexible_service_groups',
          resourceId: serviceGroup.quote_id,
        },
      },
    ]);
  };

  useEffect(() => {
    if (serviceGroup && !serviceGroup.locked && !isLongDistanceTransit) {
      fetchServiceGroupAvailability();
    }
  }, [serviceGroup, isLongDistanceTransit]);

  if (!serviceGroup || serviceGroup?.locked) return null;

  if (isLongDistanceTransit)
    return (
      <Tooltip title="LD Transit does not utiltize availability">
        <Info
          fontSize="large"
          sx={{
            color: ({ palette }) => (palette.mode === 'light' ? palette.secondary.light : palette.neutral.main),
          }}
        />
      </Tooltip>
    );

  if (isFlexQuotesLoading) return <CircularProgress size={35} />;

  if (isFlexQuotesError) {
    return (
      <Tooltip title="Error occurred fetching availability for this service">
        <Error fontSize="large" color="error" />
      </Tooltip>
    );
  }

  return available ? (
    <Tooltip title="Service is available at this date/time">
      <EventAvailable color="success" fontSize="large" onClick={fetchServiceGroupAvailability} />
    </Tooltip>
  ) : (
    <Tooltip title="Service is NOT available at this date/time">
      <EventBusy color="error" fontSize="large" onClick={fetchServiceGroupAvailability} />
    </Tooltip>
  );
};

const ServiceGroupPriceLock = ({ serviceGroupId }) => {
  const { canPriceLock } = useContext(QuoteContext);
  const { record: quoteRecord, refetch, isLoading: isQuoteLoading, isFetching: isQuoteFetching } = useShowContext();

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const serviceGroup = quoteRecord?.service_groups.find(({ id }) => id === serviceGroupId);

  const { mutate: operationUpdate, isLoading: isMutationLoading } = useMutation(([resource, params]) =>
    dataProvider.operationUpdate(resource, params),
  );

  const onSuccess = () => {
    mx.track('Order Management - Quote - Service Price Lock Updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    notify('Service price lock updated', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating service price lock', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating service price lock - ${error}`, { type: 'error' });
  };

  const updateServiceGroupPriceLock = (lockPrice) => {
    const operationsNeeded =
      serviceGroup?.services?.map((service) => ({
        op: 'update',
        path: `/service_groups/${serviceGroup.id}/service`,
        value: {
          service_id: service.id,
          lock_price: lockPrice,
        },
      })) ?? [];

    if (!operationsNeeded?.length) {
      return;
    }

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const isPriceLocked = serviceGroup?.services?.every((service) => service.lock_price);

  const isLoading = isQuoteLoading || isQuoteFetching || isMutationLoading;

  if (!canPriceLock) return null;

  return (
    <>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1}>
            <Box display="flex">
              <AttachMoney />
              {isPriceLocked ? <Lock /> : <NoEncryption />}
            </Box>
            {isPriceLocked ? (
              <>
                <Typography>Honoring original price</Typography>
                <Tooltip
                  title={
                    <>
                      <Typography component={Box} mb={1} variant="caption">
                        Service price on quote may update when service is changed but NOT apply to order when using Update
                        Order
                      </Typography>
                      <Typography variant="caption">The current price of service on order will be used</Typography>
                    </>
                  }
                >
                  <Help />
                </Tooltip>
              </>
            ) : (
              <>
                <Typography>Not honoring original price</Typography>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Service price on quote may update when service is changed and WILL apply to order when using Update
                      Order
                    </Typography>
                  }
                >
                  <Help />
                </Tooltip>
              </>
            )}
          </Box>
          <Box>
            {isPriceLocked ? (
              <Button disabled={!!isLoading} onClick={() => updateServiceGroupPriceLock(false)} variant="outlined">
                Unlock Price
              </Button>
            ) : (
              <Button disabled={!!isLoading} onClick={() => updateServiceGroupPriceLock(true)} variant="outlined">
                Lock Price
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
      <Divider />
    </>
  );
};

ServiceGroupPriceLock.propTypes = {
  serviceGroupId: string.isRequired,
};

const ServiceGroups = () => {
  const { data } = useListContext();

  if (!data) return null;

  return data.map((serviceGroup) => (
    <Card key={serviceGroup.id} component={Box} mb={2}>
      <RecordContextProvider value={serviceGroup}>
        <Box p={2} display="flex" alignItems="center" gap={1}>
          {serviceGroup?.services?.length > 1 ? (
            <Typography variant="h5">Full Service -</Typography>
          ) : (
            <Typography variant="h5">{getServiceName(serviceGroup?.services?.[0]?.service_id)} -</Typography>
          )}
          <PrefDateTimeField
            label="Start"
            source="start_datetime"
            timezoneSource="start_timezone"
            dayjsFormat="M/DD/YYYY - h a"
            textFieldProps={{
              variant: 'h5',
            }}
          />
          <Box display="flex" gap={1}>
            <Chip icon={<Schedule />} label={`${serviceGroup.duration}`} />
            <ServiceGroupTotalHourlyChip />
          </Box>
          <Box display="flex" gap={1}>
            <ArrayField source="services">
              <ServiceDetails />
            </ArrayField>
          </Box>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <ServiceGroupInfo />
          </Box>
        </Box>
        <Divider />
        <CardContent>
          <ArrayField source="locations">
            <Locations />
          </ArrayField>
        </CardContent>
        <Divider />
        <ServiceGroupPriceLock serviceGroupId={serviceGroup.id} />
        <CardContent>
          <ServiceGroupActions />
        </CardContent>
      </RecordContextProvider>
    </Card>
  ));
};

export default ServiceGroups;
