import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import mx from 'mixpanel-browser';
import { func, shape, string } from 'prop-types';
import { useDataProvider, useNotify, useShowContext } from 'react-admin';
import Calendar from 'react-calendar';
import { useMutation } from 'react-query';

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, Switch } from '@mui/material';

import * as resources from '@/api/resources';

import CalendarDay from './CalendarDay';
import SlotQuote from './SlotQuote';

dayjs.extend(utc);
dayjs.extend(tz);

const hasService = (serviceGroup, serviceId) =>
  _.findIndex(serviceGroup?.services, (service) => service.service_id === serviceId) >= 0;

const getSwimLanes = (serviceGroup) => {
  const isMovingServices = hasService(serviceGroup, 'PACKINGSERVICES') || hasService(serviceGroup, 'LOADINGUNLOADING');

  if (isMovingServices) {
    return [8, 14];
  }
  return [8, 11, 14, 17];
};

const allBusinessHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

const FlexCalendar = ({ initialStartDate, quoteId, serviceGroupId, selectQuote, selectedQuote }) => {
  const { record: quoteRecord } = useShowContext();
  const [value, setValue] = useState();
  const [quoteRange, setQuoteRange] = useState({
    start: dayjs(initialStartDate).startOf('month').toDate(),
    end: dayjs(initialStartDate).endOf('month').toDate(),
  });

  const serviceGroup = _.find(quoteRecord?.service_groups, { id: serviceGroupId });
  const slotHours = getSwimLanes(serviceGroup);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const [flexibleQuoteDataByDate, setFlexibleQuoteDataByDate] = useState({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    mutate: createMultiple,
    data: { data: flexibleServiceGroupQuoteData } = {},
    isLoading: isFlexQuotesLoading,
  } = useMutation(([resource, params]) => dataProvider.createMultiple(resource, params));

  const onSuccessFlexibleQuotes = () => {
    mx.track('Order Management - Quote - Flexible Quotes created', {
      quoteId: quoteRecord?.id,
    });
  };

  const onErrorFlexibleQuotes = (error) => {
    mx.track('Order Management - Quote - Error creating Flexible Quotes', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(error.body.detail ?? 'Error occurred creating flexible quotes', { type: 'error' });
  };

  const loadFlexibleServiceGroupQuotes = () => {
    mx.track('Order Management - Quote - Flexible Quotes requested', {
      quoteId: quoteRecord?.id,
      quoteRangeStart: dayjs(quoteRange.start).format('YYYY-MM-DD'),
      quoteRangeEnd: dayjs(quoteRange.end).format('YYYY-MM-DD'),
    });

    createMultiple(
      [
        resources.QUOTES,
        {
          data: {
            service_group_id: serviceGroupId,
            start_date: dayjs(quoteRange.start).format('YYYY-MM-DD'),
            end_date: dayjs(quoteRange.end).format('YYYY-MM-DD'),
            local_hours: slotHours,
          },
          meta: {
            subResource: 'flexible_service_groups',
            resourceId: quoteId,
          },
        },
      ],
      {
        onSuccess: onSuccessFlexibleQuotes,
        onError: onErrorFlexibleQuotes,
      },
    );
  };

  const loadFlexibleServiceGroupQuotesSelectedDateAllHours = () => {
    mx.track('Order Management - Quote - Flexible Quotes requested for non swimlane hours', {
      quoteId: quoteRecord?.id,
    });
    // This loads quotes for all business hours for a single date to allow reschedule outside of swimlane
    createMultiple(
      [
        resources.QUOTES,
        {
          data: {
            service_group_id: serviceGroupId,
            start_date: dayjs(value).format('YYYY-MM-DD'), // Uses `value` as date as that's the currently selected date on calendar
            end_date: dayjs(value).format('YYYY-MM-DD'),
            local_hours: allBusinessHours,
          },
          meta: {
            subResource: 'flexible_service_groups',
            resourceId: quoteId,
          },
        },
      ],
      {
        onSuccess: onSuccessFlexibleQuotes,
        onError: onErrorFlexibleQuotes,
      },
    );
  };

  const onActiveStartDateChange = ({ activeStartDate, view }) => {
    selectQuote(null);
    setValue(null);
    if (view === 'month') {
      const newStartDate = dayjs(activeStartDate).toDate();
      const newEndDate = dayjs(activeStartDate).endOf('month').add(1, 'day').toDate();

      setQuoteRange({
        start: newStartDate < dayjs().toDate() ? dayjs().toDate() : newStartDate,
        end: newEndDate,
      });
    }
  };

  const [showUnavailable, setShowUnavailable] = useState(false);

  const tileContent = ({ activeStartDate, date, view }) => {
    if (view === 'month') {
      const localDate = dayjs(date).tz(serviceGroup.start_timezone, true);
      const calendarDateString = localDate.format('YYYY-MM-DD');
      let dayQuotes = flexibleQuoteDataByDate?.[calendarDateString];
      if (!showUnavailable) {
        dayQuotes = dayQuotes?.filter((quote) => quote.is_available);
      }
      const minCostQuote = _.minBy(dayQuotes, (quote) => quote.total_cost);

      return (
        <CalendarDay
          activeStartDate={activeStartDate}
          quoteData={dayQuotes}
          date={date}
          view={view}
          minCost={minCostQuote?.total_cost}
          serviceGroupId={serviceGroupId}
          slotHours={slotHours}
        />
      );
    }
    return null;
  };

  const tileClassName = ({ view, date }) => {
    if (view === 'month') {
      const calendarDateString = dayjs(date).tz(serviceGroup.start_timezone, true).format('YYYY-MM-DD');

      return flexibleQuoteDataByDate?.[calendarDateString]?.some((quote) => quote.is_available)
        ? 'available'
        : 'unavailable';
    }
    return '';
  };

  const tileDisabled = ({ view, date }) => {
    if (view === 'month') {
      const localDate = dayjs(date).tz(serviceGroup.start_timezone, true);
      const calendarDateString = localDate.format('YYYY-MM-DD');
      const dayQuotes = flexibleQuoteDataByDate?.[calendarDateString];
      if (showUnavailable && dayQuotes?.length) return false;
      if (!showUnavailable && dayQuotes?.some((quote) => quote.is_available)) return false;
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (flexibleServiceGroupQuoteData) {
      const newQuoteDataByDate = flexibleServiceGroupQuoteData.reduce((prevQuotes, quote) => {
        const quoteDateString = dayjs(quote.start_datetime).tz(quote.timezone).format('YYYY-MM-DD');
        return {
          ...prevQuotes,
          [quoteDateString]: [
            ...(prevQuotes[quoteDateString] ?? []),
            {
              ...quote,
              local_datetime: dayjs(quote.start_datetime).tz(quote.timezone).format(),
              local_hour: dayjs(quote.start_datetime).tz(quote.timezone).format('h A'),
            },
          ],
        };
      }, {});

      setFlexibleQuoteDataByDate({
        ...flexibleQuoteDataByDate,
        ...newQuoteDataByDate,
      });
    }
  }, [flexibleServiceGroupQuoteData]);

  const selectedDateString = serviceGroup ? dayjs(value).tz(serviceGroup.start_timezone, true).format('YYYY-MM-DD') : '';
  let slotQuotes = flexibleQuoteDataByDate?.[selectedDateString];
  if (!showUnavailable) {
    slotQuotes = slotQuotes?.filter((quote) => quote.is_available);
  }

  useEffect(() => {
    loadFlexibleServiceGroupQuotes();
  }, []);

  if (!quoteRecord || !serviceGroup) return null;

  return (
    <Box width="100%" className="quote-flex-calendar" mt={3}>
      <Card>
        <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="outlined"
            disabled={isFlexQuotesLoading}
            endIcon={isFlexQuotesLoading ? <CircularProgress size={18} /> : null}
            onClick={loadFlexibleServiceGroupQuotes}
          >
            Load Quotes
          </Button>
          <FormControlLabel
            control={<Switch checked={showUnavailable} onChange={() => setShowUnavailable(!showUnavailable)} />}
            label="Show Unavailable"
          />
        </Box>
        <Divider />
        <Calendar
          defaultActiveStartDate={initialStartDate}
          onActiveStartDateChange={onActiveStartDateChange}
          minDetail="year"
          value={value}
          onChange={onChange}
          tileClassName={tileClassName}
          tileContent={tileContent}
          tileDisabled={tileDisabled}
          minDate={dayjs().toDate()}
          prevLabel={<KeyboardArrowLeft />}
          prev2Label={<KeyboardDoubleArrowLeft />}
          nextLabel={<KeyboardArrowRight />}
          next2Label={<KeyboardDoubleArrowRight />}
        />
        <Divider />
        <CardContent>
          <Box>
            <Grid container spacing={2} my={2} justifyContent="center">
              {slotQuotes?.map((quote) => (
                <Grid item xs={slotQuotes?.length > 4 ? 2.5 : 3} key={quote.id}>
                  <SlotQuote key={quote.id} quote={quote} selectTimeSlot={selectQuote} active={selectedQuote === quote.id} />
                </Grid>
              ))}
            </Grid>
            {slotQuotes?.length && slotQuotes?.length <= 4 ? (
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  disabled={isFlexQuotesLoading}
                  endIcon={isFlexQuotesLoading ? <CircularProgress size={18} /> : null}
                  onClick={loadFlexibleServiceGroupQuotesSelectedDateAllHours}
                >
                  Show Non-Swimlane Hours
                </Button>
              </Box>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

FlexCalendar.propTypes = {
  initialStartDate: shape({}).isRequired,
  quoteId: string.isRequired,
  serviceGroupId: string.isRequired,
  selectQuote: func.isRequired,
  selectedQuote: string,
};

FlexCalendar.defaultProps = {
  selectedQuote: null,
};

export default FlexCalendar;
