import React from 'react';

import { SimpleShowLayout, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CUSTOMER_ACCOUNT_DETAIL_SECTION, CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';
import MarketNameField from '@/fields/MarketNameField';

import CustomerDetailsSectionHeader from '../Common/SectionHeader';

const CustomerDetailsOverviewSection = () => (
  <Card component={Box}>
    <CustomerDetailsSectionHeader
      title="Overview"
      tab={CUSTOMER_ACCOUNT_TAB.DETAILS}
      section={CUSTOMER_ACCOUNT_DETAIL_SECTION.OVERVIEW}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="first_name" emptyText="N/A" />
            <TextField source="last_name" emptyText="N/A" />
            <TextField source="status" emptyText="N/A" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="phone" label="Phone number" emptyText="N/A" />
            <TextField source="email" label="Email address" emptyText="N/A" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="account_record_type_name" label="Account type" emptyText="N/A" />
            <MarketNameField source="primary_market" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default CustomerDetailsOverviewSection;
