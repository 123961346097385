import React from 'react';
import { Labeled, RecordContextProvider, useListContext } from 'react-admin';

import { Box } from '@mui/material';

import CurrencyField from '@/fields/CurrencyField';
import objectSum from '@/utils/objectSum';
import { onlyAuthorizedTransactions, onlyCompletedTransactions, onlyOpenTransactions } from '@/utils/transactions/filters';

const TransactionTotals = () => {
  const { data } = useListContext();

  if (!data) return null;

  const retainedTotal = data.filter(onlyCompletedTransactions).reduce(objectSum('retained_amount'), 0);
  const authorizedTotal = data.filter(onlyAuthorizedTransactions).reduce(objectSum('amount'), 0);
  const openTotal = data.filter(onlyOpenTransactions).reduce(objectSum('amount'), 0);

  return (
    <Box display="flex" justifyContent="center">
      <RecordContextProvider value={{ retainedTotal, authorizedTotal, openTotal }}>
        <Box display="flex" justifyContent="space-between" width={400} mt={2} mb={1}>
          <Labeled label="Open Total" title="Total amount from open transactions yet to be authorized">
            <CurrencyField source="openTotal" />
          </Labeled>
          <Labeled label="Authorized Total" title="Total amount from authorized transactions awaiting capture">
            <CurrencyField source="authorizedTotal" />
          </Labeled>
          <Labeled label="Retained Total" title="Total retained amount from completed transactions">
            <CurrencyField source="retainedTotal" />
          </Labeled>
        </Box>
      </RecordContextProvider>
    </Box>
  );
};

export default TransactionTotals;
